import React from "react"

const Security = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.2 33.11">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M28.61,4.48l-.05-.24L14.62,0h0L.67,4.23h0l-.05.24A37.2,37.2,0,0,0,.46,16.86C1.91,25.43,6.77,31,14.5,33.08l.1,0,.11,0c7.73-2,12.58-7.65,14-16.22A37.16,37.16,0,0,0,28.61,4.48ZM26.84,9.33a.41.41,0,0,0,.37.24.45.45,0,0,0,.16,0l1-.42a37.5,37.5,0,0,1-.22,6.47H26.82a.4.4,0,0,0,0,.8H28c0,.11,0,.22,0,.33a24,24,0,0,1-1.87,6.15l-.88-.41a.4.4,0,0,0-.34.73l.88.4a16.52,16.52,0,0,1-4.05,5.09L21.13,28a.4.4,0,0,0-.63.49l.55.72a18.47,18.47,0,0,1-6.05,3v-1.2a.4.4,0,0,0-.8,0v1.2A18.42,18.42,0,0,1,8,29.09l.48-.63A.4.4,0,1,0,7.89,28l-.48.62a16.84,16.84,0,0,1-4-5.07l.71-.33a.4.4,0,0,0-.33-.73l-.73.34a23.89,23.89,0,0,1-1.83-6.06c0-.11,0-.23-.05-.35h.93a.4.4,0,0,0,0-.8h-1A35.31,35.31,0,0,1,.89,9.06L2,9.54a.45.45,0,0,0,.16,0,.4.4,0,0,0,.16-.77L.94,8.21c.13-1.66.33-2.87.41-3.32l4-1.22.81,1.18A.39.39,0,0,0,6.5,5,.38.38,0,0,0,6.72,5a.39.39,0,0,0,.11-.55l-.67-1L14.2,1v1.2a.4.4,0,0,0,.8,0V1l8.17,2.49-.64.93a.39.39,0,0,0,.11.55.38.38,0,0,0,.22.07.39.39,0,0,0,.33-.17L24,3.71l3.87,1.18a32,32,0,0,1,.42,3.39l-1.22.52A.41.41,0,0,0,26.84,9.33Z"
            fill="#010101"
          />
          <path
            d="M14.6,14.85a.4.4,0,0,0,.4-.4v-5a.4.4,0,1,0-.8,0v5A.4.4,0,0,0,14.6,14.85Z"
            fill="#010101"
          />
          <path
            d="M22.25,19.42,15.9,16.2a.4.4,0,0,0-.54.18.39.39,0,0,0,.18.53l6.35,3.22a.41.41,0,0,0,.18.05.4.4,0,0,0,.18-.76Z"
            fill="#010101"
          />
          <path
            d="M17.54,20.35a5.2,5.2,0,0,1-2.94.9,5.26,5.26,0,0,1-3.11-9.5.4.4,0,0,0,.09-.56.41.41,0,0,0-.56-.08A6.06,6.06,0,0,0,14.6,22.05,6,6,0,0,0,18,21a.4.4,0,0,0,.11-.55A.41.41,0,0,0,17.54,20.35Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default Security
