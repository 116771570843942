import React from "react"

const Cyclists = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.49 25.44">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path d="M8.39,25.44a.41.41,0,0,1,0-.81A7.59,7.59,0,1,0,1,15.39a.4.4,0,0,1-.48.31.41.41,0,0,1-.3-.48A8.4,8.4,0,1,1,8.39,25.44Z" />
          <path d="M3.59,23.84a.4.4,0,0,1-.24-.09A8.31,8.31,0,0,1,0,17a8.53,8.53,0,0,1,.2-1.82.39.39,0,0,1,.48-.3.39.39,0,0,1,.3.47,7.62,7.62,0,0,0,2.85,7.73.4.4,0,0,1-.24.72Z" />
          <path d="M33.1,25.44a8.4,8.4,0,1,1,5.14-15,.4.4,0,0,1-.49.63A7.52,7.52,0,0,0,33.1,9.45,7.59,7.59,0,1,0,40.69,17a7.42,7.42,0,0,0-.46-2.6.41.41,0,0,1,.24-.52.4.4,0,0,1,.51.24A8.28,8.28,0,0,1,41.49,17,8.4,8.4,0,0,1,33.1,25.44Z" />
          <path d="M33.1,17.54a.5.5,0,0,1-.48-.35L29.4,6.43H16L9.32,16.54h9.46a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H9.07a.86.86,0,0,1-.76-.45.89.89,0,0,1,0-.89L15.4,5.43H30.15L33.58,16.9a.5.5,0,0,1-.34.62A.36.36,0,0,1,33.1,17.54Z" />
          <path d="M19.88,16a.51.51,0,0,1-.46-.31L13.36,1h-2a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5H14l6.31,15.35a.49.49,0,0,1-.27.65A.41.41,0,0,1,19.88,16Z" />
          <path d="M21.51,16.43a.51.51,0,0,1-.32-.12.49.49,0,0,1-.06-.7l8.14-9.86V1.85A.89.89,0,0,1,30.15,1h4a2.77,2.77,0,0,1,2.7,2.61,2.69,2.69,0,0,1-.73,2,2.79,2.79,0,0,1-1.95.9.51.51,0,0,1-.52-.49.49.49,0,0,1,.48-.51,1.8,1.8,0,0,0,1.26-.58,1.68,1.68,0,0,0,.46-1.23A1.76,1.76,0,0,0,34.18,2H30.27V6.11L21.9,16.24A.51.51,0,0,1,21.51,16.43Z" />
          <path d="M8.39,20.45A3.41,3.41,0,1,1,11.8,17,3.41,3.41,0,0,1,8.39,20.45Zm0-6A2.61,2.61,0,1,0,11,17,2.61,2.61,0,0,0,8.39,14.44Z" />
          <path d="M33.21,20.45A3.41,3.41,0,1,1,36.61,17,3.41,3.41,0,0,1,33.21,20.45Zm0-6a2.61,2.61,0,1,0,2.6,2.6A2.61,2.61,0,0,0,33.21,14.44Z" />
          <path d="M20.52,18.75A1.71,1.71,0,1,1,22.23,17,1.71,1.71,0,0,1,20.52,18.75Zm0-2.62a.91.91,0,1,0,.91.91A.91.91,0,0,0,20.52,16.13Z" />
          <path d="M17.87,1H13.7a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h4.17a.5.5,0,0,1,.5.5A.5.5,0,0,1,17.87,1Z" />
        </g>
      </g>
    </svg>
  )
}

export default Cyclists
