import React from "react";
import styled from "styled-components";

const goUpButton = ({ propertyName }) => {
  const goTopHandler = e => {
    e.preventDefault();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <StyledLogo onClick={goTopHandler} href="/">
      {propertyName}
    </StyledLogo>
  );
};

export default goUpButton;

const StyledLogo = styled.a`
  font-family: Montserrat-SemiBold;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;

  height: 100%;
  letter-spacing: 3.27px;
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding-right: 60px;
  line-height: normal;
  @media screen and (max-width: 420px) {
    font-size: 15px;
    letter-spacing: 2.27px;
  }
  @media screen and (max-width: 360px) {
    padding-right: 140px;
  }
`;
