import React from "react";

const ElectricCables = () => {
  return (
    <svg data-name="Warstwa 1" viewBox="0 0 45 45">
      <path
        d="M23.49,5H35.42a0,0,0,0,1,0,0v5.88a6,6,0,0,1-6,6h0a5.88,5.88,0,0,1-5.88-5.88V5A0,0,0,0,1,23.49,5Z"
        transform="translate(40.46 -18.44) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="28.51"
        y="9.93"
        width="15.97"
        height="2.15"
        rx="1.08"
        transform="translate(47.5 -25.48) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M39.69,5.71h0A1.11,1.11,0,0,1,40.8,6.83V10a0,0,0,0,1,0,0H38.57a0,0,0,0,1,0,0V6.83a1.11,1.11,0,0,1,1.11-1.11Z"
        transform="translate(47.52 -31.85) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M39.69,11.33h0a1.11,1.11,0,0,1,1.11,1.11v3.13a0,0,0,0,1,0,0H38.57a0,0,0,0,1,0,0V12.44A1.11,1.11,0,0,1,39.69,11.33Z"
        transform="translate(53.14 -26.24) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M17,28h0a6,6,0,0,1,6,6V39.9a0,0,0,0,1,0,0H11.09a0,0,0,0,1,0,0v-6A5.88,5.88,0,0,1,17,28Z"
        transform="translate(50.99 16.88) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="2.03"
        y="32.86"
        width="15.97"
        height="2.15"
        rx="1.08"
        transform="translate(43.95 23.92) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M5.71,28.64H7.93a0,0,0,0,1,0,0v3.13a1.11,1.11,0,0,1-1.11,1.11h0a1.11,1.11,0,0,1-1.11-1.11V28.64A0,0,0,0,1,5.71,28.64Z"
        transform="translate(37.58 23.94) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M5.71,34.26H7.93a0,0,0,0,1,0,0v3.13A1.11,1.11,0,0,1,6.82,38.5h0a1.11,1.11,0,0,1-1.11-1.11V34.26A0,0,0,0,1,5.71,34.26Z"
        transform="translate(43.2 29.55) rotate(90)"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M11.09,13a5.67,5.67,0,0,0-1.36,3.7v.13a5.72,5.72,0,0,0,5.72,5.72H30.77a5.72,5.72,0,0,1,5.72,5.72h0a5.72,5.72,0,0,1-5.72,5.72H23"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="23.49"
        y1="10.93"
        x2="17.06"
        y2="10.93"
        fill="none"
        stroke="#010101"
      />
    </svg>
  );
};

export default ElectricCables;
