import React from "react";
import styled from "styled-components";
import Map from "./Map";
import { fontColor } from "./variables";
import tram from "../data/svg/tram.svg";
import train from "../data/svg/train.svg";
import gallery from "../data/svg/gallery.svg";
import metro from "../data/svg/metro.svg";
import highwaySvg from "../data/svg/highway.svg";
import bus from "../data/svg/bus.svg";
import airport from "../data/svg/airport.svg";

const Location = ({
  zeroOffset,
  propertyName,
  data,
  mapData,
  transportTime,
  transportIconLoc,
}) => {
  return (
    <StyledSection id={`${data.sectionTitle.toUpperCase()}`}>
      {!zeroOffset ? (
        <div>
          <StyledMapContainer id="mapContainer">
            <Map
              propertyName={propertyName}
              mapData={mapData}
              lat={mapData.lat}
              lng={mapData.lng}
              transportIconLoc={transportIconLoc}
            />
          </StyledMapContainer>
          <StyledMapDescriptionWrapper>
            <StyledInnerWrapper>
              <StyledTransportDiv>
                <StyledTransportHeader>
                  {data.addressLabel}
                </StyledTransportHeader>
                <StyledAdress href={data.adressUrl} target="_blank">
                  {data.adress}
                </StyledAdress>
                <StyledParagraph>
                  {data.centerDistance}
                  <StyledSpan>{transportTime.timeFromCenter}</StyledSpan>
                </StyledParagraph>
                <StyledFlexDiv>
                  <div>
                    <StyledTransportHeader>
                      {data.onFootLabel}
                    </StyledTransportHeader>
                    <StyledCell>
                      <StyledIcon src={bus} alt={data.busLabel} />
                      <StyledParagraph>
                        {data.busLabel}
                        <StyledSpan>
                          {transportTime.busTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledIcon src={tram} alt={data.tramLabel} />
                      <StyledParagraph>
                        {data.tramLabel}
                        <StyledSpan>
                          {transportTime.tramTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                  </div>
                  <div>
                    <StyledTransportHeader>
                      {data.byCarLabel}
                    </StyledTransportHeader>
                    {/* <StyledCell>
                      <StyledIcon src={metro} alt={data.subwayLabel} />
                      <StyledParagraph>
                        {data.subwayLabel}
                        <StyledSpan>
                          {transportTime.subwayTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell> */}
                    <StyledCell>
                      <StyledIcon src={train} alt={data.trainstationLabel} />
                      <StyledParagraph>
                        {data.trainstationLabel}
                        <StyledSpan>
                          {transportTime.trainstationTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    {/* <StyledCell>
                      <StyledIcon src={highwaySvg} alt={data.highwayLabel} />
                      <StyledParagraph>
                        {data.highwayLabel}
                        <StyledSpan>
                          {transportTime.highwayTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell> */}
                    <StyledCell>
                      <StyledParagraph>
                        <StyledIcon src={gallery} alt={data.galleryLabel} />
                        {data.galleryLabel}
                        <StyledSpan>
                          {transportTime.galleryTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                    <StyledCell>
                      <StyledIcon src={airport} alt={data.airportLabel} />
                      <StyledParagraph>
                        {data.airportLabel}
                        <StyledSpan>
                          {transportTime.airportTransportTime}
                        </StyledSpan>
                      </StyledParagraph>
                    </StyledCell>
                  </div>
                </StyledFlexDiv>
              </StyledTransportDiv>
            </StyledInnerWrapper>
          </StyledMapDescriptionWrapper>
        </div>
      ) : null}
    </StyledSection>
  );
};
export default Location;

const StyledIcon = styled.img`
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;
const StyledFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (max-width: 610px) {
    flex-direction: column;
  }
`;

const StyledParagraph = styled.p`
  color: ${fontColor};
  font-family: OpenSans;
  font-size: 18px;
  @media screen and (max-width: 610px) {
    font-size: 15px;
  }
`;

const StyledAdress = styled.a`
  text-decoration: none;
  font-family: OpenSans-SemiBold;
  color: ${fontColor};
  font-size: 18px;
  margin-bottom: 20px;
  @media screen and (max-width: 610px) {
    margin-bottom: 15px;
    margin-top: 5px;
    font-size: 16px;
  }
`;
const StyledSpan = styled.span`
  margin-left: 10px;
  font-family: OpenSans-SemiBold;
  color: ${fontColor};
  font-size: 18px;
  @media screen and (max-width: 610px) {
    font-size: 15px;
  }
`;
const StyledTransportHeader = styled.h4`
  color: ${fontColor};
  font-family: OpenSans-SemiBold;
  font-size: 18px;
  margin-top: 18px;
  @media screen and (max-width: 610px) {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;
const StyledCell = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 13px;
`;

const StyledTransportDiv = styled.div`
  border-radius: 12px;
  z-index: 4;
  background: rgba(255, 255, 255, 0.7);
  padding: 20px;
  margin-bottom: 30px;
  @media screen and (max-width: 768px) {
    background: #efefef;
    padding: 10px;
  }
`;

const StyledSection = styled.section`
  position: relative;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    background: #efefef;
  }
`;

const StyledMapDescriptionWrapper = styled.div`
  border-radius: 12px;
  width: 420px;
  position: absolute;
  left: 50px;
  top: 100px;
  z-index: 4;
  display: block;
  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
`;
const StyledInnerWrapper = styled.div`
  padding: 10px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
`;

const StyledMapContainer = styled.div`
  width: 100%;
`;
