import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import styled from "styled-components";
import marker from "../data/images/Map_marker.svg";
import { fontColor } from "./variables";
import tram from "../data/svg/tram.svg";
import train from "../data/svg/train.svg";
import galleryIcon from "../data/svg/gallery.svg";
import metro from "../data/svg/metro.svg";
import highwaySvg from "../data/svg/highway.svg";
import Bus from "./svg/Bus";

class Map extends Component {
  render() {
    const { propertyName, mapData, lng, lat, transportIconLoc } = this.props;
    const center = {
      lat,
      lng,
    };
    const mapOptions = {
      styles: [
        {
          featureType: "all",
          elementType: "labels.icon",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
    };

    let zoom;
    window.innerWidth > 1024 ? (zoom = 16) : (zoom = 15);

    const tramPins = transportIconLoc.tram.map((tramStop, index) => {
      return (
        <StyledBadge key={index} lat={tramStop.lat} lng={tramStop.lng}>
          <StyledImage src={tram} alt="tram" />
        </StyledBadge>
      );
    });
    const busPins = transportIconLoc.bus.map((busStop, index) => {
      return (
        <StyledBadge key={index} lat={busStop.lat} lng={busStop.lng}>
          <Bus />
        </StyledBadge>
      );
    });
    const trainPins = transportIconLoc.trainstation.map(
      (trainstation, index) => {
        return (
          <StyledBadge
            lat={trainstation.lat}
            lng={trainstation.lng}
            key={index}
          >
            <StyledImage src={train} alt="train"></StyledImage>
          </StyledBadge>
        );
      }
    );
    const galleryPins = transportIconLoc.gallery.map((gallery, index) => {
      return (
        <StyledBadge lat={gallery.lat} lng={gallery.lng} key={index}>
          <StyledImage src={galleryIcon} alt="gallery"></StyledImage>
        </StyledBadge>
      );
    });

    const highwayPins = transportIconLoc.highway.map((highway, index) => {
      return (
        <StyledBadge key={index} lat={highway.lat} lng={highway.lng}>
          <StyledImage src={highwaySvg} alt="highway" />
        </StyledBadge>
      );
    });

    const subwayPins = transportIconLoc.subway.map((subway, index) => {
      return (
        <StyledBadge key={index} lat={subway.lat} lng={subway.lng}>
          <StyledImage src={metro} alt="metro" />
        </StyledBadge>
      );
    });

    return (
      <MapWrapper>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDG34tpSoljcImdZwyMwlH-kiKWDi_cTTQ" }}
          center={center}
          zoom={zoom}
          options={mapOptions}
        >
          <StyledPin
            alt="pin"
            src={marker}
            lat={mapData.lat}
            lng={mapData.lng}
          ></StyledPin>
          {tramPins}
          {trainPins}
          {galleryPins}
          {subwayPins}
          {highwayPins}
          {busPins}
        </GoogleMapReact>
      </MapWrapper>
    );
  }
}

export default Map;

const MapWrapper = styled.div`
  height: calc(100vh - 81px);
  min-height: 705px;
  width: 100%;
  @media screen and (max-width: 768px) {
    height: 50vh;
    min-height: unset;
  }
`;

const StyledPin = styled.img`
  width: 45px;
  height: 68px;
  transform: translate(-50%, -100%);
  @media screen and (max-width: 768px) {
    width: 28px;
    height: 38px;
  }
`;

const StyledImage = styled.img`
  width: 80%;
  height: 80%;
  object-fit: contain;
`;

const StyledBadge = styled.div`
  display: flex;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
  transform: translate(-50%, -100%);
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 30px;
    height: 30px;
  }
`;
