import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const initialSchemaData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'Długosza Business Park',
  image: 'https://dlugoszabp.pl/static/c8c90af80a42efd324033a289cabd54a/0ea4e/M7.jpg',
  '@id': '',
  priceRange: 'od 46 zł / m2',
  url: 'https://dlugoszabp.pl/',
  description:
    'Nowoczesny park biznesowy do wynajęcia w centrum Wrocławia ul. Jana Długosza 48-60 ➤ Wynajem powierzchni biurowo magazynowej ➤ Sprawdź!',
  disambiguatingDescription:
    'Długosza Business Park zlokalizowany jest w północno-wschodniej części Wrocławia, z bardzo dobrym dostępem do infrastruktury drogowej. Budynek oferuje powierzchnię biurową i magazynową oraz miejsca parkingowe. Zastosowane w budynku rozwiązania konstrukcyjne dają możliwość elastycznej aranżacji przestrzeni oraz optymalne doświetlenie powierzchni światłem dziennym.',
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'Wrocław',
    addressRegion: 'Województwo dolnośląskie',
    postalCode: '51-162',
    streetAddress: 'Jana Długosza 60a',
    addressCountry: 'PL',
    telephone: '+48 501 498 353',
    email: 'jacek.halusiak@savills.pl',
  },
  openingHoursSpecification: {
    '@type': 'OpeningHoursSpecification',
    dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    opens: '09:00',
    closes: '18:00',
  },
  sameAs: [
    'https://dlugoszabp.pl/',
    'https://www.cylex-polska.pl/firmy/biznes-park-d%c5%82ugosza-48-60-13580373.html',
    'https://wroclaw.dlawas.info/wizytowka-firmy/biznes-park-dlugosza-48-60/cid,14500,c',
    'https://mapa.targeo.pl/biznes-park-dlugosza-48-60-dlugosza-jana-48-51-162-wroclaw~20517306/biuro-nieruchomosci/adres',
    'https://sci24.pl/katalog-firm/67277/dlugosza-biznes-park-wroclaw/',
    'https://www.tuwroclaw.com/katalog-firm,biznes-park-dlugosza-48-60,kfi5-2894-7909.html',
    'https://www.baza-firm.com.pl/nieruchomo%C5%9Bci-zarz%C4%85dzanie-sprzeda%C5%BC-wynajem-wyceny/wroc%C5%82aw/d%C5%82ugosza-business-park/pl/377149.html',
    'https://kb.pl/firmy-budowlane/wykonawca/biznes-park-dugosza-48-60/',
    'https://www.tvsudecka.pl/firmy/3442,biznes-park-dlugosza-48-60',
    'https://dlugoszabp.oferteo.pl/',
    'https://oferia.pl/wykonawca/biznes-park-dlugosza-48-60/1195088',
    'https://www.pkt.pl/firma/park-biznesowy-7810167',
    'https://godzinyotwarcia24.pl/Park-Biznesowy-D%C5%82ugosza-Wroc%C5%82aw-Rb82jm',
    'https://www.tvbogatynia.pl/firmy/10737,park-biznesowy-dlugosza-48-60',
  ],
}

const schemaData = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'Długosza Business Park - Biuro do wynajęcia',
  alternateName: 'Business Park Długosza - Powierzchnia do wynajęcia',
  url: 'https://dlugoszabp.pl/',
  logo: 'https://dlugoszabp.pl/static/m7-logo-7f935e26d54327dd1458748450e968d2.svg',
  sameAs: 'https://uk.linkedin.com/company/martley-capital-group/',
}

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: { defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`,
      }

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <html lang="pl" />
            <title>{seo.title}</title>
            <meta name="theme-color" content="#000"></meta>
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {(article ? true : null) && <meta property="og:type" content="article" />}
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && <meta property="og:description" content={seo.description} />}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && <meta name="twitter:description" content={seo.description} />}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script type="application/ld+json">{JSON.stringify(initialSchemaData)}</script>
            <script type="application/ld+json">{JSON.stringify(schemaData)}</script>
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`
