import React from "react";

const Canteen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29.23 31.74">
      <g>
        <g>
          <path d="M22.66,31.74H22.5a2.62,2.62,0,0,1-1.89-1L10.23,15.2a4,4,0,0,1-3.58-1.38L.08,4.61A.45.45,0,0,1,.19,4a.45.45,0,0,1,.63.1l6.55,9.19a3.12,3.12,0,0,0,3,1l.3-.07,10.68,16a1.72,1.72,0,0,0,1.22.61,1.55,1.55,0,0,0,1.17-.4,1.66,1.66,0,0,0,.09-2.15L12,13.26l.15-.27s1.06-1.86,0-3.27C11,8.06,5.81.78,5.76.71a.45.45,0,0,1,.1-.63.46.46,0,0,1,.63.11c0,.07,5.22,7.34,6.43,9a3.93,3.93,0,0,1,.17,4L24.52,27.73a2.55,2.55,0,0,1-.18,3.34A2.37,2.37,0,0,1,22.66,31.74Z" />
          <path d="M9.41,11.44A.46.46,0,0,1,9,11.26L2.69,2.65A.45.45,0,0,1,2.79,2a.44.44,0,0,1,.62.09l6.36,8.61a.46.46,0,0,1-.1.63A.43.43,0,0,1,9.41,11.44Z" />
          <path d="M7.66,31.4a2.49,2.49,0,0,1-1.46-.47,2.52,2.52,0,0,1-1-1.71,2.56,2.56,0,0,1,.52-2l5.07-6.46a.45.45,0,0,1,.63-.08.46.46,0,0,1,.08.64L6.39,27.82a1.65,1.65,0,0,0-.33,1.27,1.61,1.61,0,0,0,.66,1.11,1.68,1.68,0,0,0,2.34-.44l3.58-5.58a.45.45,0,0,1,.76.49L9.81,30.25A2.67,2.67,0,0,1,7.66,31.4Z" />
          <path d="M18.81,16.32a.44.44,0,0,1-.36-.18.45.45,0,0,1,.09-.63l1.35-1,.11,0a10.11,10.11,0,0,0,3.51-1.13,8.85,8.85,0,0,0,3.9-3.84C28.31,7.64,29,4,27.12,2.34a4.39,4.39,0,0,0-3.07-.88,7.46,7.46,0,0,0-5.32,3.07,9.53,9.53,0,0,0-1.67,5.74v.09c0,.15.05.39.09.64s.07.49.09.67a2.93,2.93,0,0,1,0,.76c-.05.34-.13.81-.26,1.4a.45.45,0,0,1-.54.34.46.46,0,0,1-.34-.54c.13-.56.2-1,.25-1.33a1.82,1.82,0,0,0,0-.54c0-.16-.05-.4-.08-.63s-.08-.53-.1-.69A10.26,10.26,0,0,1,18,4c.59-.78,2.75-3.33,6-3.43a5.22,5.22,0,0,1,3.68,1.11c2.31,2,1.57,6,.51,8.23a9.8,9.8,0,0,1-4.28,4.24,11,11,0,0,1-3.7,1.22l-1.16.87A.44.44,0,0,1,18.81,16.32Z" />
        </g>
      </g>
    </svg>
  );
};

export default Canteen;
