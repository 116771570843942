import React from "react"

const Ceiling = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.79 37.79">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M16,18H2a2,2,0,0,1-2-2V2A2,2,0,0,1,2,0H16a2,2,0,0,1,2,2V16A2,2,0,0,1,16,18ZM2,.8A1.2,1.2,0,0,0,.8,2V16A1.2,1.2,0,0,0,2,17.17H16a1.2,1.2,0,0,0,1.2-1.2V2A1.2,1.2,0,0,0,16,.8Z"
            fill="#010101"
          />
          <path
            d="M35.79,37.79h-14a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2h14a2,2,0,0,1,2,2v14A2,2,0,0,1,35.79,37.79Zm-14-17.17a1.2,1.2,0,0,0-1.2,1.2v14a1.2,1.2,0,0,0,1.2,1.2h14a1.2,1.2,0,0,0,1.2-1.2v-14a1.2,1.2,0,0,0-1.2-1.2Z"
            fill="#010101"
          />
          <path
            d="M16,37.79H2a2,2,0,0,1-2-2v-14a2,2,0,0,1,2-2H16a2,2,0,0,1,2,2v14A2,2,0,0,1,16,37.79ZM2,20.62a1.2,1.2,0,0,0-1.2,1.2v14A1.2,1.2,0,0,0,2,37H16a1.2,1.2,0,0,0,1.2-1.2v-14a1.2,1.2,0,0,0-1.2-1.2Z"
            fill="#010101"
          />
          <path
            d="M35.79,18h-14a2,2,0,0,1-2-2V2a2,2,0,0,1,2-2h14a2,2,0,0,1,2,2V16A2,2,0,0,1,35.79,18ZM21.82.8A1.2,1.2,0,0,0,20.62,2V16a1.2,1.2,0,0,0,1.2,1.2h14A1.2,1.2,0,0,0,37,16V2A1.2,1.2,0,0,0,35.79.8Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default Ceiling
