import React from "react"

const Klimatyzacja = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.25 33.12">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M37.57,22.19H3.68A3.68,3.68,0,0,1,0,18.51V3.68A3.68,3.68,0,0,1,3.68,0H7.82a.4.4,0,0,1,0,.8H3.68A2.88,2.88,0,0,0,.8,3.68V18.51a2.88,2.88,0,0,0,2.88,2.88H37.57a2.88,2.88,0,0,0,2.88-2.88V3.68A2.88,2.88,0,0,0,37.57.8H12.65a.4.4,0,1,1,0-.8H37.57a3.68,3.68,0,0,1,3.68,3.68V18.51A3.68,3.68,0,0,1,37.57,22.19Z"
            fill="#010101"
          />
          <path
            d="M40.85,14.78H.4a.4.4,0,1,1,0-.8H40.85a.4.4,0,1,1,0,.8Z"
            fill="#010101"
          />
          <path
            d="M29.62,22.19h-18v-2a2,2,0,0,1,2-2h14a2,2,0,0,1,2,2Zm-17.19-.8H28.82v-1.2a1.2,1.2,0,0,0-1.2-1.2h-14a1.2,1.2,0,0,0-1.2,1.2Z"
            fill="#010101"
          />
          <path
            d="M33,11.8H25.47a.4.4,0,0,1,0-.8H33a.4.4,0,1,1,0,.8Z"
            fill="#010101"
          />
          <path
            d="M37.39,11.8H35.18a.4.4,0,0,1,0-.8h2.21a.4.4,0,1,1,0,.8Z"
            fill="#010101"
          />
          <path
            d="M31.81,30.66a2.15,2.15,0,0,1-.45,0,3.72,3.72,0,0,1-2.78-3.81l0-2.49a.42.42,0,0,1,.4-.41.38.38,0,0,1,.4.4l0,2.49a3,3,0,0,0,2.11,3,2.12,2.12,0,0,0,1.95-.73,1.83,1.83,0,0,0,.21-2.17,1.58,1.58,0,0,0-2-.34,1.28,1.28,0,0,0-.44.59.41.41,0,0,1-.52.22.39.39,0,0,1-.22-.52,2.07,2.07,0,0,1,.73-.95,2.38,2.38,0,0,1,3.07.5,2.59,2.59,0,0,1-.2,3.17A2.88,2.88,0,0,1,31.81,30.66Z"
            fill="#010101"
          />
          <path
            d="M28.31,32.38a.39.39,0,0,1-.27-.1,8.65,8.65,0,0,1-2.84-8.09.4.4,0,0,1,.79.13A8.12,8.12,0,0,0,26.58,29a7.91,7.91,0,0,0,2,2.65.4.4,0,0,1,0,.56A.41.41,0,0,1,28.31,32.38Z"
            fill="#010101"
          />
          <path
            d="M22.22,26.46a.4.4,0,0,1-.4-.41v-1.8a.4.4,0,0,1,.8,0v1.8A.4.4,0,0,1,22.22,26.46Z"
            fill="#010101"
          />
          <path
            d="M22.22,33.12a.4.4,0,0,1-.4-.4V27.59a.4.4,0,0,1,.8,0v5.13A.4.4,0,0,1,22.22,33.12Z"
            fill="#010101"
          />
          <path
            d="M9.44,30.66a2.86,2.86,0,0,1-2.25-1.05A2.6,2.6,0,0,1,7,26.44a2.38,2.38,0,0,1,3.07-.5,2.08,2.08,0,0,1,.74.95.4.4,0,0,1-.74.3,1.37,1.37,0,0,0-.45-.59,1.58,1.58,0,0,0-2,.34,1.86,1.86,0,0,0,.21,2.17,2.12,2.12,0,0,0,2,.73,3,3,0,0,0,2.11-3.05l0-2.45.4,0,.4,0,0,2.46a3.72,3.72,0,0,1-2.78,3.81A2.15,2.15,0,0,1,9.44,30.66Z"
            fill="#010101"
          />
          <path
            d="M13,32.38a.39.39,0,0,1-.3-.14.4.4,0,0,1,0-.56,7.78,7.78,0,0,0,2-2.65,8.14,8.14,0,0,0,.6-4.71.4.4,0,0,1,.32-.46.41.41,0,0,1,.47.33,8.82,8.82,0,0,1-.66,5.17,8.57,8.57,0,0,1-2.19,2.92A.39.39,0,0,1,13,32.38Z"
            fill="#010101"
          />
          <path
            d="M19,26.46a.4.4,0,0,1-.4-.41v-1.8a.4.4,0,0,1,.8,0v1.8A.4.4,0,0,1,19,26.46Z"
            fill="#010101"
          />
          <path
            d="M19,33.12a.4.4,0,0,1-.4-.4V27.59a.4.4,0,0,1,.8,0v5.13A.4.4,0,0,1,19,33.12Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default Klimatyzacja
