import React from "react";

const Cctv = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <path
        d="M10,20.41v2.71a12.39,12.39,0,0,0,24.78,0V20.41"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M3.47,7.87H42.76a0,0,0,0,1,0,0v7.27a5.27,5.27,0,0,1-5.27,5.27H8.73a5.27,5.27,0,0,1-5.27-5.27V7.87a0,0,0,0,1,0,0Z"
        fill="none"
        stroke="#010101"
      />
      <circle cx="22.39" cy="27.58" r="1.6" fill="none" stroke="#010101" />
      <circle cx="17.54" cy="27.58" r="0.38" fill="none" stroke="#010101" />
      <circle cx="18.96" cy="24.15" r="0.38" fill="none" stroke="#010101" />
      <circle cx="22.39" cy="22.73" r="0.38" fill="none" stroke="#010101" />
      <circle cx="25.81" cy="24.15" r="0.38" fill="none" stroke="#010101" />
      <circle cx="27.23" cy="27.58" r="0.38" fill="none" stroke="#010101" />
      <circle cx="25.81" cy="31" r="0.38" fill="none" stroke="#010101" />
      <circle cx="22.39" cy="32.42" r="0.38" fill="none" stroke="#010101" />
      <circle cx="18.96" cy="31" r="0.38" fill="none" stroke="#010101" />
      <line
        x1="3.47"
        y1="14.04"
        x2="31.62"
        y2="14.04"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="42.76"
        y1="14.04"
        x2="36.65"
        y2="14.04"
        fill="none"
        stroke="#010101"
      />
    </svg>
  );
};

export default Cctv;
