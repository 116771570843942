import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import Img from "gatsby-image";
import Chevron from "./svg/Chevron";
import Close from "./svg/Close";
import { success } from "./variables";

export default class LightBox extends Component {
  state = {
    nav1: null,
    nav2: null,
  };

  keyNavigationHandler = (e) => {
    if (e.keyCode === 39 && this.slider1) {
      this.slider1.slickNext();
    }
    if (e.keyCode === 37 && this.slider1) {
      this.slider1.slickPrev();
    }
  };

  componentDidMount() {
    window.addEventListener("click", this.props.closeGalleryHandler);
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
    window.addEventListener("keydown", this.keyNavigationHandler);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.props.closeGalleryHandler);
  }

  render() {
    const images = this.props.images;
    const slides = images.map((image, index) => {
      return (
        <Slide onClick={this.lightBoxInitHandler} key={index}>
          <StyledImg fluid={image.childImageSharp.fluid} />
        </Slide>
      );
    });
    const thumbs = images.map((image, index) => {
      return (
        <Thumb key={index}>
          <StyledThumb fluid={image.childImageSharp.fluid} />
        </Thumb>
      );
    });
    const settings = {
      responsive: [
        {
          breakpoint: 768,
          settings: {
            dots: true,
          },
        },
      ],
    };
    return (
      <StyledLightBoxContainer>
        <SliderWrapper>
          <OvalArrowLeft>
            <Chevron
              color="#fff"
              ovalSize="52px"
              arrowSize="22px"
              backgroundColor={success}
            />
          </OvalArrowLeft>
          <FirstSlider
            asNavFor={this.state.nav2}
            ref={(slider) => (this.slider1 = slider)}
            {...settings}
          >
            {slides}
          </FirstSlider>
          <OvalArrowRight>
            <Chevron
              color="#fff"
              ovalSize="52px"
              arrowSize="22px"
              rotate="180deg"
              backgroundColor={success}
            />
          </OvalArrowRight>
        </SliderWrapper>
        <StyledThumbsWrapper>
          <Slider
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            slidesToShow={5}
            swipeToSlide={true}
            focusOnSelect={true}
            arrows={false}
          >
            {thumbs}
          </Slider>
        </StyledThumbsWrapper>
        <Mask />
        <StyledClosing onClick={this.closeGalleryHandler}>
          <Close />
        </StyledClosing>
      </StyledLightBoxContainer>
    );
  }
}

const Slide = styled.div`
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const StyledImg = styled(Img)`
  max-height: 60vh;
  margin: auto;
  cursor: pointer;
  img {
    object-fit: contain !important;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;
const Thumb = styled.div`
  cursor: pointer;
`;

const StyledThumb = styled(Img)`
  height: 180px;
  @media screen and (max-width: 1420px) {
    height: 140px;
  }
  @media screen and (max-width: 1160px) {
    height: 120px;
  }
  @media screen and (max-width: 960px) {
    height: 100px;
  }
  img {
    object-fit: contain !important;
  }
`;

const StyledThumbsWrapper = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const StyledLightBoxContainer = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 23;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Mask = styled.div`
  background: rgba(0, 0, 0, 0.95);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

const StyledClosing = styled.div`
  font-size: 30px;
  color: #fff;
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 240;
  cursor: pointer;
`;
const FirstSlider = styled(Slider)`
  margin-top: 50px;
  @media screen and (max-width: 768px) {
    margin-top: calc(20vh);
    height: 100vh;
    .slick-dots {
      top: 60vh;
      .active {
        color: #fff !important;
      }
      li button:before {
        color: #fff !important;
        font-size: 16px;
      }
    }
  }
`;
const OvalArrowRight = styled.div`
  position: absolute;
  left: 20px;
  top: calc(50%);
  z-index: 2;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const OvalArrowLeft = styled.div`
  position: absolute;
  right: 20px;
  top: calc(50%);
  z-index: 2;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;
const SliderWrapper = styled.div`
  position: relative;
`;
