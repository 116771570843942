import React, { useState, useEffect } from "react";
import styled from "styled-components";
import LightBox from "./LightBox";
import CarouselMain from "./Carousel";
import DoubleSlider from "./DoubleSlider";
import Chevron from "./svg/Chevron";
import { chevronAnimation } from "./Openinig";
import { fontColor, success } from "./variables";
import disableScroll from "disable-scroll";

const Gallery = ({ images, data }) => {
  const [lightBox, showLightbox] = useState(false);

  const showLightBoxHandler = (e) => {
    e.preventDefault();
    disableScroll.on();
    !lightBox ? showLightbox(true) : showLightbox(false);
  };
  const closeGalleryHandler = (e) => {
    if (e.target.nodeName === "DIV") {
      disableScroll.off();
      showLightbox(false);
    }
  };

  const [mobileDisplay, setMobileDisplay] = useState(
    window.innerWidth > 576 ? false : true
  );

  useEffect(() => {
    window.addEventListener("resize", mobileMenuHandler);
    return () => {
      window.removeEventListener("resize", mobileMenuHandler);
    };
  }, []);

  const mobileMenuHandler = () => {
    if (window.innerWidth < 576) {
      setMobileDisplay(true);
    }
    if (window.innerWidth > 576) {
      setMobileDisplay(false);
    }
  };

  return (
    <StyledSection>
      <StyledHeaderContainer>
        <StyledHeader>{data.sectionTitle} </StyledHeader>
        <StyledGalleryTitle>{data.galleryTitle}</StyledGalleryTitle>
      </StyledHeaderContainer>
      {lightBox ? (
        <LightBox
          closeGalleryHandler={closeGalleryHandler}
          images={images}
        ></LightBox>
      ) : null}
      {mobileDisplay ? (
        <DoubleSlider images={images} />
      ) : (
        <CarouselMain images={images} />
      )}
      <StyledLink onClick={showLightBoxHandler} href="/">
        {data.showAllLabel}
        <div style={{ pointerEvents: "none" }}>
          <Chevron
            arrowSize="20px"
            ovalSize="40px"
            color="#fff"
            backgroundColor={success}
          />
        </div>
      </StyledLink>
    </StyledSection>
  );
};

export default Gallery;

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  font-size: 20px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.29px;
  color: ${fontColor};
  margin-top: 40px;
  margin-left: 30px;
  div {
    margin-left: 15px;
  }
  &:hover {
    div {
      @media screen and (min-width: 992px) {
        animation: ${chevronAnimation} 0.6s ease-in-out infinite;
      }
    }
  }
`;

const StyledSection = styled.section`
  padding-bottom: 80px;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    padding-bottom: 40px;
    padding-top: 40px;
  }
`;

const StyledHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const StyledHeader = styled.h2`
  display: flex;
  align-items: center;
  height: 40px;
  text-align: center;

  font-size: 18px;
  line-height: 3.39;
  letter-spacing: 5.81px;
  color: ${success};
  text-transform: uppercase;
  margin: 15px;
`;

const StyledGalleryTitle = styled.h3`
  font-size: 45px;
  font-weight: bold;
  line-height: 1.36;
  letter-spacing: 0.65px;
  text-align: center;
  color: ${fontColor};
  margin-top: 0px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    font-size: 35px;
  }
  @media screen and (max-width: 576px) {
    font-size: 28px;
  }
`;
