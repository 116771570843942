import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import Img from "gatsby-image"
import LightBox from "./LightBox"

export default class DoubleSlider extends Component {
  state = {
    nav1: null,
    nav2: null,
    lightBox: false,
    tabIndex: -1,
  }
  
  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
      lightBox: false,
      tabIndex: 0,
    })
  }

  lightBoxInitHandler = e => {
    this.setState({
      lightBox: true,
    })
  }
  closeGalleryHandler = e => {
    if (e.target.nodeName !== "IMG" && e.target.className !== "oval") {
      this.setState({
        lightBox: false,
      })
    }
  }

  render() {
    
    const images = this.props.images
    const lightBox =
      this.state.lightBox === true ? (
        <LightBox
          closeGalleryHandler={this.closeGalleryHandler}
          images={images}
          tabIndex={this.state.tabIndex}
        />
      ) : null

    const slides = images.map((image, index) => {
      return (
        <Slide onClick={this.lightBoxInitHandler} key={index}>
          <StyledImg fluid={image.childImageSharp.fluid} />
        </Slide>
      )
    })

    return (
      <StyledSliderContainer>
        <Slider
          focusOnSelect={true}
          asNavFor={this.state.nav2}
          afterChange={this.nextClick}
          infinite={true}
          slidesToShow={1}
          autoplay={true}
          centerPadding={"50px"}
        >
          {slides}
        </Slider>
        {lightBox}
      </StyledSliderContainer>
    )
  }
}

const Slide = styled.div`
  width: 600px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const StyledImg = styled(Img)`
  width: 90%;
  border-radius: 15px;
  margin: auto;
  cursor: pointer;
`

const StyledSliderContainer = styled.div`
  display: none;
  width: 100%;
  margin: 0 auto;
  position: relative;
  @media screen and (max-width: 576px) {
    display: block;
  }
`
