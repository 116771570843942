import React from "react"
import styled from "styled-components"

const Arrow = ({ color = "#fff", arrowSize, rotate = "0" }) => {
  return (
    
      <StyledSvg
        rotate={rotate}
        height={arrowSize}
        viewBox="0 0 11 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          
          stroke="none"
          fill={color}
        >
          <g

            transform="translate(-1046.000000, -1993.000000)"
            fill={color}
          >
            <g
          
              transform="translate(1046.000000, 1993.000000)"
            >
              <path
                d="M1.24033555,19.7928896 C1.09840578,19.9308195 0.921048924,20 0.726421361,20 C0.530908121,20 0.35465836,19.9308195 0.21272859,19.7928896 C-0.0709095301,19.5168142 -0.0709095301,19.0687575 0.21272859,18.7926822 L9.24685736,9.99943427 L0.21272859,1.20726395 C-0.0709095301,0.930973079 -0.0709095301,0.482269809 0.21272859,0.207056516 C0.495481033,-0.0690188387 0.956476011,-0.0690188387 1.24033555,0.207056516 L10.7872714,9.49943831 C11.0709095,9.77551367 11.0709095,10.2242169 10.7872714,10.4996457 L1.24033555,19.7928896"
                id="Fill-1"
              ></path>
            </g>
          </g>
        </g>
      </StyledSvg>
  )
}
export default Arrow

const StyledSvg = styled.svg`
  transform: rotate(${props => props.rotate});
  transform-origin: 40% 50%;
`

