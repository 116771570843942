import React from "react";

const PPOZ = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <path
        d="M21,13.18h8.12a4.59,4.59,0,0,1,4.59,4.59V38.43a2.65,2.65,0,0,1-2.65,2.65H19.14a2.68,2.68,0,0,1-2.68-2.68V17.71A4.54,4.54,0,0,1,21,13.18Z"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="20.53"
        y="10.14"
        width="9.1"
        height="3.04"
        rx="0.88"
        fill="none"
        stroke="#010101"
      />
      <rect
        x="22.44"
        y="8.06"
        width="5.16"
        height="2.07"
        rx="0.88"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M36.52,3.92H21.37a.85.85,0,0,0-.84.84V7.22a.85.85,0,0,0,.84.84h7.42a.85.85,0,0,0,.84-.84v-1h6.89a.85.85,0,0,0,.84-.84V4.76A.85.85,0,0,0,36.52,3.92Z"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M10.86,36H6.24A1.14,1.14,0,0,1,5.1,34.72L6,24.13a1.14,1.14,0,0,1,1.14-1h2.6a1.14,1.14,0,0,1,1.14,1L12,34.69A1.13,1.13,0,0,1,10.86,36Z"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="8.55"
        y1="17.49"
        x2="8.55"
        y2="23.09"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M20.23,6H13.67a5.12,5.12,0,0,0-5.12,5.12V13.6"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M31,21.22h-3.1A2.78,2.78,0,0,0,25.08,24v8.85A2.17,2.17,0,0,0,27.24,35h3.45"
        fill="none"
        stroke="#010101"
      />
    </svg>
  );
};

export default PPOZ;
