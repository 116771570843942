import React from "react"

const Detectors = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.88 35.41">
      <g data-name="Warstwa 2">
        <g data-name="Warstwa 1">
          <path
            d="M14.92,28.49a.4.4,0,1,0-.68.44,6.39,6.39,0,0,0,5,2.68h.16a6.15,6.15,0,0,0,5.25-2.85.39.39,0,0,0-.14-.54.41.41,0,0,0-.55.13,5.37,5.37,0,0,1-4.7,2.46A5.51,5.51,0,0,1,14.92,28.49Z"
            fill="#010101"
          />
          <path
            d="M19.34,27.83h.08a2.85,2.85,0,0,0,2.45-1.33.4.4,0,0,0-.69-.41,2.12,2.12,0,0,1-3.49.05.4.4,0,1,0-.67.43A3,3,0,0,0,19.34,27.83Z"
            fill="#010101"
          />
          <path
            d="M28.05,30.08a.4.4,0,0,0-.55.14,9.55,9.55,0,0,1-8.4,4.39,9.87,9.87,0,0,1-7.73-4.14.39.39,0,0,0-.55-.12.4.4,0,0,0-.12.56,10.7,10.7,0,0,0,8.38,4.5h.26a10.34,10.34,0,0,0,8.85-4.78A.41.41,0,0,0,28.05,30.08Z"
            fill="#010101"
          />
          <path
            d="M38.49.77A1.91,1.91,0,0,0,37,0h-35A1.89,1.89,0,0,0,.39.77,1.9,1.9,0,0,0,.08,2.46l2.36,8.11A1.92,1.92,0,0,0,4.28,12H5.74l2.5,8.65c.28,1.5,1.18,2.5,2.25,2.5h17.9c1.06,0,2-1,2.23-2.46L33.15,12h1.43a1.94,1.94,0,0,0,1.85-1.39L38.8,2.46A1.9,1.9,0,0,0,38.49.77ZM29.85,20.46c-.21,1.09-.81,1.85-1.46,1.85H10.49c-.66,0-1.26-.76-1.47-1.89L6.57,12H32.32ZM38,2.24l-2.37,8.11a1.14,1.14,0,0,1-1.08.81H4.28a1.15,1.15,0,0,1-1.08-.81L.85,2.24a1.1,1.1,0,0,1,.18-1A1.1,1.1,0,0,1,1.93.8H37a1.1,1.1,0,0,1,.9.45A1.13,1.13,0,0,1,38,2.24Z"
            fill="#010101"
          />
          <path
            d="M17.83,21.4h3.33a1.08,1.08,0,0,0,1.09-1l.67-6.48a1.08,1.08,0,0,0-.28-.84,1.12,1.12,0,0,0-.82-.37H17.08a1.12,1.12,0,0,0-.82.37A1.08,1.08,0,0,0,16,14l.75,6.48A1.09,1.09,0,0,0,17.83,21.4Zm-1-7.78a.3.3,0,0,1,.22-.1h4.74a.33.33,0,0,1,.23.1.33.33,0,0,1,.07.23l-.66,6.48a.3.3,0,0,1-.3.27H17.83a.3.3,0,0,1-.3-.26l-.75-6.48A.3.3,0,0,1,16.86,13.62Z"
            fill="#010101"
          />
          <path
            d="M25,21.4h1.79a1.05,1.05,0,0,0,1-.75L29.66,14a1,1,0,0,0-1-1.31H25.92a1,1,0,0,0-1,.9L24,20.23a1,1,0,0,0,1,1.17Zm.66-7.68a.24.24,0,0,1,.23-.2h2.74a.23.23,0,0,1,.19.1.22.22,0,0,1,0,.2l-1.84,6.61a.24.24,0,0,1-.23.17H25a.23.23,0,0,1-.17-.08.22.22,0,0,1-.06-.18Z"
            fill="#010101"
          />
          <path
            d="M11.16,20.65a1,1,0,0,0,1,.75h1.78a1,1,0,0,0,.78-.35,1,1,0,0,0,.25-.82l-.89-6.61a1,1,0,0,0-1-.9H10.32a1,1,0,0,0-1,1.31Zm-1-7a.22.22,0,0,1,.19-.1h2.74a.24.24,0,0,1,.23.2l.88,6.62a.19.19,0,0,1-.05.18.23.23,0,0,1-.18.08H12.16a.24.24,0,0,1-.23-.17l-1.84-6.61A.2.2,0,0,1,10.13,13.62Z"
            fill="#010101"
          />
        </g>
      </g>
    </svg>
  )
}

export default Detectors
