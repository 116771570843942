import React from "react";
import styled from "styled-components";
import { fontColor } from "../variables";

const Bus = () => {
  return (
    <StyledSvg width="44px" height="44px" viewBox="0 0 44 44" version="1.1">
      <g stroke="none" fill="none">
        <g transform="translate(-827.000000, -2583.000000)" fill={fontColor}>
          <g transform="translate(827.000000, 2583.000000)">
            <path d="M6.47826087,33.5263158 L37.5217391,33.5263158 L37.5217391,8.47368421 L6.47826087,8.47368421 L6.47826087,33.5263158 Z M38.2608696,35 L5.73913043,35 C5.3303913,35 5,34.6706316 5,34.2631579 L5,7.73684211 C5,7.33010526 5.3303913,7 5.73913043,7 L38.2608696,7 C38.6696087,7 39,7.33010526 39,7.73684211 L39,34.2631579 C39,34.6706316 38.6696087,35 38.2608696,35 L38.2608696,35 Z"></path>
            <path d="M6.92572611,34.5 L8.37664668,37.49925 L35.6211359,37.5 L37.0742739,34.5 L6.92572611,34.5 Z M35.6211359,39 L8.37886409,39 C7.8097307,39 7.30120632,38.67825 7.04990067,38.16075 L5.07567303,34.08 C4.96332462,33.84825 4.97662904,33.57375 5.11189061,33.354 C5.24715218,33.1335 5.48367515,33 5.7394156,33 L38.2613235,33 C38.5163249,33 38.753587,33.1335 38.8881094,33.354 C39.023371,33.57375 39.0366754,33.84825 38.924327,34.08 L36.9500993,38.16 C36.6987937,38.67825 36.1902693,39 35.6211359,39 L35.6211359,39 Z"></path>
            <path d="M6.47826087,6.54545455 L37.5217391,6.54545455 L37.5217391,3.63636364 C37.5217391,2.43345455 36.5268696,1.45454545 35.3043478,1.45454545 L8.69565217,1.45454545 C7.47313043,1.45454545 6.47826087,2.43345455 6.47826087,3.63636364 L6.47826087,6.54545455 Z M38.2608696,8 L5.73913043,8 C5.3303913,8 5,7.67418182 5,7.27272727 L5,3.63636364 C5,1.63127273 6.65786957,0 8.69565217,0 L35.3043478,0 C37.3421304,0 39,1.63127273 39,3.63636364 L39,7.27272727 C39,7.67418182 38.6696087,8 38.2608696,8 L38.2608696,8 Z"></path>
            <path d="M12.4666667,6.57142857 L31.5333333,6.57142857 L31.5333333,5.14285714 C31.5333333,4.74928571 31.2048,4.42857143 30.8,4.42857143 L13.2,4.42857143 C12.7952,4.42857143 12.4666667,4.74928571 12.4666667,5.14285714 L12.4666667,6.57142857 Z M32.2666667,8 L11.7333333,8 C11.3278,8 11,7.68 11,7.28571429 L11,5.14285714 C11,3.96142857 11.9870667,3 13.2,3 L30.8,3 C32.0129333,3 33,3.96142857 33,5.14285714 L33,7.28571429 C33,7.68 32.6722,8 32.2666667,8 L32.2666667,8 Z"></path>
            <path d="M4.6,16.4444444 L5.4,16.4444444 L5.4,12.5555556 L4.6,12.5555556 L4.6,16.4444444 Z M6.2,18 L3.8,18 C3.3576,18 3,17.6515556 3,17.2222222 L3,11.7777778 C3,11.3484444 3.3576,11 3.8,11 L6.2,11 C6.6424,11 7,11.3484444 7,11.7777778 L7,17.2222222 C7,17.6515556 6.6424,18 6.2,18 L6.2,18 Z"></path>
            <path d="M1.33333333,10.5384615 L1.33266667,10.5384615 L1.33333333,17.4615385 L2.66666667,17.4615385 L2.66666667,10.5384615 L1.33333333,10.5384615 Z M2.66666667,19 L1.33333333,19 C0.598,19 0,18.31 0,17.4615385 L0,10.5384615 C0,9.69 0.598,9 1.33333333,9 L2.66666667,9 C3.402,9 4,9.69 4,10.5384615 L4,17.4615385 C4,18.31 3.402,19 2.66666667,19 L2.66666667,19 Z"></path>
            <path d="M38.6,16.4444444 L39.4,16.4444444 L39.4,12.5555556 L38.6,12.5555556 L38.6,16.4444444 Z M40.2,18 L37.8,18 C37.3576,18 37,17.6515556 37,17.2222222 L37,11.7777778 C37,11.3484444 37.3576,11 37.8,11 L40.2,11 C40.6424,11 41,11.3484444 41,11.7777778 L41,17.2222222 C41,17.6515556 40.6424,18 40.2,18 L40.2,18 Z"></path>
            <path d="M41.3333333,10.5384615 L41.3333333,17.4615385 L42.6673333,17.4615385 L42.6666667,10.5384615 L41.3333333,10.5384615 Z M42.6666667,19 L41.3333333,19 C40.598,19 40,18.31 40,17.4615385 L40,10.5384615 C40,9.69 40.598,9 41.3333333,9 L42.6666667,9 C43.402,9 44,9.69 44,10.5384615 L44,17.4615385 C44,18.31 43.402,19 42.6666667,19 L42.6666667,19 Z"></path>
            <path d="M9.4,38.5555556 L9.4,41.6666667 C9.4,42.096 9.7136,42.4444444 10.1,42.4444444 L12.9,42.4444444 C13.2864,42.4444444 13.6,42.096 13.6,41.6666667 L13.6,38.5555556 L9.4,38.5555556 Z M12.9,44 L10.1,44 C8.9422,44 8,42.9531111 8,41.6666667 L8,37.7777778 C8,37.3476667 8.3129,37 8.7,37 L14.3,37 C14.6871,37 15,37.3476667 15,37.7777778 L15,41.6666667 C15,42.9531111 14.0578,44 12.9,44 L12.9,44 Z"></path>
            <path d="M30.4,38.5555556 L30.4,41.6666667 C30.4,42.096 30.7136,42.4444444 31.1,42.4444444 L33.9,42.4444444 C34.2864,42.4444444 34.6,42.096 34.6,41.6666667 L34.6,38.5555556 L30.4,38.5555556 Z M33.9,44 L31.1,44 C29.9422,44 29,42.9531111 29,41.6666667 L29,37.7777778 C29,37.3476667 29.3129,37 29.7,37 L35.3,37 C35.6871,37 36,37.3476667 36,37.7777778 L36,41.6666667 C36,42.9531111 35.0578,44 33.9,44 L33.9,44 Z"></path>
            <path d="M6.42857143,29.6666667 L12.8571429,29.6666667 C13.2514286,29.6666667 13.5714286,29.368 13.5714286,29 C13.5714286,28.632 13.2514286,28.3333333 12.8571429,28.3333333 L6.42857143,28.3333333 L6.42857143,29.6666667 Z M12.8571429,31 L5.71428571,31 C5.31928571,31 5,30.702 5,30.3333333 L5,27.6666667 C5,27.298 5.31928571,27 5.71428571,27 L12.8571429,27 C14.0385714,27 15,27.8973333 15,29 C15,30.1026667 14.0385714,31 12.8571429,31 L12.8571429,31 Z"></path>
            <path d="M8.5,31 C8.2235,31 8,30.702 8,30.3333333 L8,27.6666667 C8,27.298 8.2235,27 8.5,27 C8.7765,27 9,27.298 9,27.6666667 L9,30.3333333 C9,30.702 8.7765,31 8.5,31"></path>
            <path d="M31.1428571,28.3333333 C30.7485714,28.3333333 30.4285714,28.632 30.4285714,29 C30.4285714,29.368 30.7485714,29.6666667 31.1428571,29.6666667 L37.5714286,29.6666667 L37.5714286,28.3333333 L31.1428571,28.3333333 Z M38.2857143,31 L31.1428571,31 C29.9614286,31 29,30.1026667 29,29 C29,27.8973333 29.9614286,27 31.1428571,27 L38.2857143,27 C38.6807143,27 39,27.298 39,27.6666667 L39,30.3333333 C39,30.702 38.6807143,31 38.2857143,31 L38.2857143,31 Z"></path>
            <path d="M35.5,31 C35.2235,31 35,30.702 35,30.3333333 L35,27.6666667 C35,27.298 35.2235,27 35.5,27 C35.7765,27 36,27.298 36,27.6666667 L36,30.3333333 C36,30.702 35.7765,31 35.5,31"></path>
            <path d="M8.33333333,25 L5.66666667,25 C5.298,25 5,24.776 5,24.5 C5,24.224 5.298,24 5.66666667,24 L8.33333333,24 C8.702,24 9,24.224 9,24.5 C9,24.776 8.702,25 8.33333333,25"></path>
            <path d="M38.2631579,25 L11.7368421,25 C11.3293684,25 11,24.776 11,24.5 C11,24.224 11.3293684,24 11.7368421,24 L38.2631579,24 C38.6706316,24 39,24.224 39,24.5 C39,24.776 38.6706316,25 38.2631579,25"></path>
            <path d="M31.2857143,11 L27.7142857,11 C27.3192857,11 27,10.776 27,10.5 C27,10.224 27.3192857,10 27.7142857,10 L31.2857143,10 C31.6807143,10 32,10.224 32,10.5 C32,10.776 31.6807143,11 31.2857143,11"></path>
            <path d="M35.3333333,11 L34.6666667,11 C34.298,11 34,10.776 34,10.5 C34,10.224 34.298,10 34.6666667,10 L35.3333333,10 C35.702,10 36,10.224 36,10.5 C36,10.776 35.702,11 35.3333333,11"></path>
          </g>
        </g>
      </g>
    </StyledSvg>
  );
};

export default Bus;

const StyledSvg = styled.svg`
  width: 70%;
  height: 70%;
  object-fit: contain;
`;
