import React from "react";

const HVAC = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <circle cx="8.94" cy="9.37" r="0.38" fill="none" stroke="#010101" />
      <circle cx="36.11" cy="9.37" r="0.38" fill="none" stroke="#010101" />
      <rect
        x="3.24"
        y="3.24"
        width="38.51"
        height="38.51"
        rx="6.93"
        fill="none"
        stroke="#010101"
      />
      <circle cx="22.55" cy="22.5" r="11.25" fill="none" stroke="#010101" />
      <circle cx="8.94" cy="36.37" r="0.38" fill="none" stroke="#010101" />
      <circle cx="36.11" cy="36.37" r="0.38" fill="none" stroke="#010101" />
      <line
        x1="14.04"
        y1="15.15"
        x2="31.02"
        y2="15.15"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="14.04"
        y1="29.85"
        x2="31.02"
        y2="29.85"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="12.08"
        y1="18.82"
        x2="24.43"
        y2="18.82"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="28.03"
        y1="18.82"
        x2="33.12"
        y2="18.82"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="33.12"
        y1="26.18"
        x2="20.77"
        y2="26.18"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="17.17"
        y1="26.18"
        x2="12.08"
        y2="26.18"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="11.3"
        y1="22.5"
        x2="33.8"
        y2="22.5"
        fill="none"
        stroke="#010101"
      />
    </svg>
  );
};

export default HVAC;
