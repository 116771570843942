import React from "react";

const Teletech = () => {
  return (
    <svg
      id="Warstwa_1"
      data-name="Warstwa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
    >
      <title>ico_</title>
      <line
        x1="19.47"
        y1="19.03"
        x2="9.76"
        y2="40.39"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="25.86"
        y1="19.03"
        x2="35.57"
        y2="40.39"
        fill="none"
        stroke="#010101"
      />
      <circle cx="22.46" cy="15.03" r="4.45" fill="none" stroke="#010101" />
      <line
        x1="24.43"
        y1="25.08"
        x2="28.02"
        y2="26.9"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="19.79"
        y1="22.71"
        x2="21.23"
        y2="23.45"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="25.56"
        y1="22.82"
        x2="17.62"
        y2="26.85"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="24.9"
        y1="33.14"
        x2="32.06"
        y2="36.79"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="17.47"
        y1="29.36"
        x2="21.02"
        y2="31.17"
        fill="none"
        stroke="#010101"
      />
      <line
        x1="28.19"
        y1="29.33"
        x2="12.69"
        y2="37.22"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M15.79,10.58a5.55,5.55,0,0,0-2,3.7,5.75,5.75,0,0,0,2,4.75"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M15.79,10.58a5.55,5.55,0,0,0-2,3.7,5.75,5.75,0,0,0,2,4.75"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M12.94,7.87A9.09,9.09,0,0,0,9.61,14a9.48,9.48,0,0,0,3.33,7.79"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M9.56,4.61a13.44,13.44,0,0,0-4.89,8.93C4,20.3,9.12,24.64,9.56,25"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M29.21,10.58a5.55,5.55,0,0,1,2,3.7,5.75,5.75,0,0,1-2,4.75"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M29.21,10.58a5.55,5.55,0,0,1,2,3.7,5.75,5.75,0,0,1-2,4.75"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M32.06,7.87A9.09,9.09,0,0,1,35.39,14a9.48,9.48,0,0,1-3.33,7.79"
        fill="none"
        stroke="#010101"
      />
      <path
        d="M35.44,4.61a13.44,13.44,0,0,1,4.89,8.93C41,20.3,35.88,24.64,35.44,25"
        fill="none"
        stroke="#010101"
      />
    </svg>
  );
};

export default Teletech;
